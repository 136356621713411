import styled from 'styled-components';
import { PolymorphicComponent } from '../../util/polymorphic';
import { BaseButtonProps, variants } from '../Button/types';
import { ButtonMenuItemProps } from './types';
import Button from '../Button/Button';

interface InactiveButtonProps extends BaseButtonProps {
  forwardedAs: BaseButtonProps['as'];
}

const InactiveButton: PolymorphicComponent<InactiveButtonProps, 'button'> = styled(Button)<InactiveButtonProps>`
  background-color: transparent;
  text-align: center;
  color: ${({ theme, variant }) => (variant === variants.PRIMARY ? theme.colors.primary : theme.colors.textSubtle)};
  &:hover:not(:disabled):not(:active) {
    background-color: transparent;
  }
`;

const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, 'button'> = ({
  isActive = false,
  variant = variants.PRIMARY,
  as,
  ...props
}: ButtonMenuItemProps) => {
  if (!isActive) {
    return (
      <InactiveButton style={{ color: '#b4b4b4', fontSize: '10px', fontWeight: 500 }} forwardedAs={as} {...props} />
    );
  }
  return <Button as={as} {...props} variant={variant} />;
};

export default ButtonMenuItem;
