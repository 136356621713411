import { ChainId } from '@icecreamswap/constants'

export const setRouteApiChainName = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.TBSC:
      return 'Binance Testnet'
    case ChainId.BSC:
      return 'Binance'
    default:
      return ''
  }
}
